.color-corten {
  background-color: #224f79 !important;
}

.btn-principal-corten {
  background-color: #224f79 !important;
  text-transform: none !important;
  width: 100px;
}

.btn-principal-corten:hover {
  background: rgba(8, 45, 140, 1) !important;
}

.btn-principal-salir {
  background-color: rgb(109, 118, 129) !important;
  text-transform: none !important;
  width: 100px;
}

.btn-principal-salir:hover {
  background-color: rgb(93, 101, 112) !important;
}

.mesaje-error-form {
  color: #bf1650;
}

.mesaje-error-form::before {
  display: inline;
  content: '⚠ ';
}

.fondo-principal {
  background-color: #f5f7fa;
  border-radius: 10px;
  padding: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%);
}

.fondo-blanco {
  background-color: white;
}

.fondo-modal-header {
  background-color: #6c757d;
  text-align: center;
  color: white;
}

.borde-redondeo {
  border-radius: 20px !important;
  background-color: #f5f7fa !important;
}

.z-index-alerta {
  z-index: 10000 !important;
}

.documento-anulacion {
  background-color: rgb(250, 239, 178) !important;
}

.loaderDocument {
  position: relative;
  border-style: solid;
  box-sizing: border-box;
  border-width: 20px 40px 20px 40px;
  border-color: #224f79 #7ad4fa #96ddfc #36bbf7;
  animation: envFloating 1s ease-in infinite alternate;
  -webkit-animation: envFloating 1s ease-in infinite alternate;
}

.loaderDocument:after {
  content: '';
  position: absolute;
  right: 62px;
  top: -40px;
  height: 70px;
  width: 50px;
  background-image: linear-gradient(#224f79 40px, transparent 0),
    linear-gradient(#224f79 40px, transparent 0),
    linear-gradient(#224f79 40px, transparent 0);
  background-repeat: no-repeat;
  background-size: 30px 4px;
  background-position: 10px, 10px, 10px, 10px;
  animation: envDropping 0.75s linear infinite;
}

@keyframes envFloating {
  0% {
    transform: translate(-2px, -5px);
  }
  100% {
    transform: translate(0, 5px);
  }
}

@keyframes envDropping {
  0% {
    background-position: 100px 11px, 115px 35px, 105px 60px;
    opacity: 1;
  }
  50% {
    background-position: 0px 11px, 20px 35px, 5px 60px;
  }
  60% {
    background-position: -30px 11px, 0px 35px, -10px 60px;
  }
  75%,
  100% {
    background-position: -30px 11px, -30px 35px, -30px 60px;
    opacity: 0;
  }
}

.custom-loader {
  animation: none !important;
  border-width: 0 !important;
}
